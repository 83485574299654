<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      />
      <b10-fab-button
        v-if="hasEditPerm($route.meta.permission.idobjeto)"
        :icon="$vuetify.icons.values.edit"
        @click="clickEdit"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './MovimientoCajaViewData'
import { get } from 'vuex-pathify'
import { TABLA } from '@/utils/consts'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    editRoute: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'observaciones', label: 'Observaciones' },
        { name: 'tmovcaja_desc', label: 'Tipo' } ,
        { name: 'forma_pago_desc', label: 'Forma de pago' } ,
        { name: 'caja_desc', label: 'Caja' } ,
        { name: 'proyecto_contable_desc', label: 'Proyecto' } ,
      ],
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idmovcaja
        item.title = item.concepto
        item.subtitle = this.$options.filters.shortDate(item.fmovimiento)
        item.badge = this.$options.filters.currency(item.importe)
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.concepto
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectMovimientoCaja(
          this, this.routeParams.idmovcaja
        )
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickEdit () {
      this.$appRouter.push({
        name: this.editRoute,
        params: {
          idmovcaja: this.routeParams.idmovcaja
        },
      })
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idmovcaja,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // ficheros
      if (this.hasViewPerm(this.permissions.movimientosCajaAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
    },
    clickDetail (data) {
      let detailRoute = {
        name: null
      }
      switch (data.detail.name) {
      case 'adjuntos':
        detailRoute.name = 'ficheros__movimientoscaja__fichero-list'
        detailRoute.params = {
          id: this.routeParams.idmovcaja,
          idtabla: TABLA.movcaja.idtabla,
        }
        break
      default:
        console.error(`La opción ${data.detail.name} no está contemplada`);
      }
      if (detailRoute.name !== null) {
        this.$appRouter.push(detailRoute)
      }
    },
  },
}
</script>
