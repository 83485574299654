import { APIFilter } from '@/utils/api'
import { TABLA } from '@/utils/consts'

export default {
  async selectMovimientoCaja (Vue, idmovcaja) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idmovcaja', idmovcaja)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('movcaja.selectView', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectDetails (Vue, idordenTrabajo, esTecnico, esComercial, esVigilante) {
    let apiCalls = []
    apiCalls.push(Vue.$online.fichero.ApiCallSelectCount(idordenTrabajo, TABLA.movcaja.idtabla, esTecnico, esComercial, esVigilante))
    return await Vue.$api.batchCall(apiCalls)
  },
}
